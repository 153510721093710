:root {
  --primary: #15ede7;
  --secondary: #727272;
  // --primary: #e5354a;
  // --secondary: #28303f;
  --success: #388e3c;
  --info: #7a9efa;
  --white: #ffffff;
  --whiteDark: #ffffff;
  --black: #000000;
  --grey: #344054;
  --contrast: #e4e4e4;
  // --contrast: #f8f8f9;
  --transparent: transparent;
  --light-surface: #b3bdc7;
  --surface: #727793;
  --danger: #d32f2f;
  --warning: #ffa000;
  --green: #14ef86;

  // rgba color code
  --primary-rgba: 21, 237, 231;
  --secondary-rgba: 40, 48, 63;
  --success-rgba: 26, 145, 18;
  --info-rgba: 122, 158, 250;
  --white-rgba: 255, 255, 255;
  --danger-rgba: 211, 47, 47;
  --black-rgba: 0, 0, 0;
  --grey-rgba: 52, 64, 84;
  --contrast-rgba: 248, 248, 249;
  --warning-rgba: 255, 160, 0;
  // --light-surface-rgba:179, 189, 199;
  // --surface-rgba:114, 119, 147;

  // --theme-default: #e5354a;
  --theme-default: #04c9c4;
  --theme-green: #29c28b;
  --theme-blue: #4c70da;
  --theme-yellow: #f06c15;
  --theme-purple: #9540a3;
}

// :root {
//   --primary: #3573e5;

// rgba color code
//   --primary-rgba:53, 115, 229;
// }

$theme-secondary: var(--secondary);
$theme-success: var(--success);
$theme-white: var(--white);
$theme-whiteDark: var(--whiteDark);
$theme-black: var(--black);
$theme-grey: var(--grey);
$theme-danger: var(--danger);
$theme-warning: var(--warning);
$theme-info: var(--info);
// $theme-warning: #dea527;
$theme-primary: var(--primary);
$theme-contrast: var(--contrast);
$theme-transparent: var(--transparent);
$theme-light-surface: var(--light-surface);
$theme-surface: var(--surface);
$theme-green: var(--green);

$theme-primary-50: rgba(var(--primary-rgba), 0.05);
$theme-primary-100: rgba(var(--primary-rgba), 0.1);
$theme-primary-200: rgba(var(--primary-rgba), 0.2);
$theme-primary-400: rgba(var(--primary-rgba), 0.4);
$theme-primary-600: rgba(var(--primary-rgba), 0.6);
$theme-primary-700: rgba(var(--primary-rgba), 0.7);
$theme-primary-800: rgba(var(--primary-rgba), 0.8);
$theme-primary-900: rgba(var(--primary-rgba), 0.9);

$theme-secondary-50: rgba(var(--secondary-rgba), 0.05);
$theme-secondary-100: rgba(var(--secondary-rgba), 0.1);
$theme-secondary-200: rgba(var(--secondary-rgba), 0.2);
$theme-secondary-400: rgba(var(--secondary-rgba), 0.4);
$theme-secondary-600: rgba(var(--secondary-rgba), 0.6);
$theme-secondary-800: rgba(var(--secondary-rgba), 0.8);

$theme-success-100: rgba(var(--success-rgba), 0.1);
$theme-success-200: rgba(var(--success-rgba), 0.2);
$theme-success-400: rgba(var(--success-rgba), 0.4);
$theme-success-600: rgba(var(--success-rgba), 0.6);
$theme-success-800: rgba(var(--success-rgba), 0.8);

$theme-info-50: rgba(var(--info-rgba), 0.05);
$theme-info-100: rgba(var(--info-rgba), 0.1);
$theme-info-200: rgba(var(--info-rgba), 0.2);
$theme-info-400: rgba(var(--info-rgba), 0.4);
$theme-info-600: rgba(var(--info-rgba), 0.6);
$theme-info-800: rgba(var(--info-rgba), 0.8);

$theme-contrast-200: rgba(var(--contrast-rgba), 0.2);
$theme-contrast-400: rgba(var(--contrast-rgba), 0.4);
$theme-contrast-600: rgba(var(--contrast-rgba), 0.6);

$theme-black-25: rgba(var(--black-rgba), 0.025);
$theme-black-50: rgba(var(--black-rgba), 0.05);
$theme-black-100: rgba(var(--black-rgba), 0.1);
$theme-black-200: rgba(var(--black-rgba), 0.2);
$theme-black-300: rgba(var(--black-rgba), 0.3);
$theme-black-400: rgba(var(--black-rgba), 0.4);
$theme-black-500: rgba(var(--black-rgba), 0.5);
$theme-black-600: rgba(var(--black-rgba), 0.6);
$theme-black-700: rgba(var(--black-rgba), 0.7);
$theme-black-800: rgba(var(--black-rgba), 0.8);
$theme-black-900: rgba(var(--black-rgba), 0.9);

$theme-grey-50: rgba(var(--grey-rgba), 0.05);
$theme-grey-100: rgba(var(--grey-rgba), 0.1);
$theme-grey-200: rgba(var(--grey-rgba), 0.2);
$theme-grey-300: rgba(var(--grey-rgba), 0.3);
$theme-grey-400: rgba(var(--grey-rgba), 0.4);
$theme-grey-500: rgba(var(--grey-rgba), 0.5);
$theme-grey-600: rgba(var(--grey-rgba), 0.6);
$theme-grey-700: rgba(var(--grey-rgba), 0.7);
$theme-grey-800: rgba(var(--grey-rgba), 0.8);
$theme-grey-900: rgba(var(--grey-rgba), 0.9);

$theme-white-100: rgba(var(--white-rgba), 0.1);
$theme-white-200: rgba(var(--white-rgba), 0.2);
$theme-white-400: rgba(var(--white-rgba), 0.4);
$theme-white-600: rgba(var(--white-rgba), 0.6);
$theme-white-700: rgba(var(--white-rgba), 0.7);
$theme-white-800: rgba(var(--white-rgba), 0.8);
$theme-white-900: rgba(var(--white-rgba), 0.9);

$theme-danger-100: rgba(var(--danger-rgba), 0.1);
$theme-danger-200: rgba(var(--danger-rgba), 0.2);
$theme-danger-400: rgba(var(--danger-rgba), 0.4);
$theme-danger-600: rgba(var(--danger-rgba), 0.6);
$theme-danger-700: rgba(var(--danger-rgba), 0.7);
$theme-danger-800: rgba(var(--danger-rgba), 0.8);
$theme-danger-900: rgba(var(--danger-rgba), 0.9);

$theme-warning-100: rgba(var(--warning-rgba), 0.1);
$theme-warning-200: rgba(var(--warning-rgba), 0.2);
$theme-warning-400: rgba(var(--warning-rgba), 0.4);
$theme-warning-600: rgba(var(--warning-rgba), 0.6);
$theme-warning-700: rgba(var(--warning-rgba), 0.7);
$theme-warning-800: rgba(var(--warning-rgba), 0.8);
$theme-warning-900: rgba(var(--warning-rgba), 0.9);
$colors: (
  'secondary': $theme-secondary,
  'success': $theme-success,
  'white': $theme-white,
  'whiteDark': $theme-whiteDark,
  'black': $theme-black,
  'grey': $theme-grey,
  'info': $theme-info,
  'danger': $theme-danger,
  'warning': $theme-warning,
  'primary': $theme-primary,
  'contrast': $theme-contrast,
  'transparent': $theme-transparent,
  'light-surface': $theme-light-surface,
  'surface': $theme-surface,
  'green': $theme-green,

  'primary-50': $theme-primary-50,
  'primary-100': $theme-primary-100,
  'primary-200': $theme-primary-200,
  'primary-400': $theme-primary-400,
  'primary-600': $theme-primary-600,
  'primary-700': $theme-primary-700,
  'primary-800': $theme-primary-800,
  'primary-900': $theme-primary-900,

  'white-100': $theme-white-100,
  'white-200': $theme-white-200,
  'white-400': $theme-white-400,
  'white-600': $theme-white-600,
  'white-700': $theme-white-700,
  'white-800': $theme-white-800,
  'white-900': $theme-white-900,

  'secondary-50': $theme-secondary-50,
  'secondary-100': $theme-secondary-100,
  'secondary-200': $theme-secondary-200,
  'secondary-400': $theme-secondary-400,
  'secondary-600': $theme-secondary-600,
  'secondary-800': $theme-secondary-800,

  'success-100': $theme-success-100,
  'success-200': $theme-success-200,
  'success-400': $theme-success-400,
  'success-600': $theme-success-600,
  'success-800': $theme-success-800,

  'info-100': $theme-info-100,
  'info-200': $theme-info-200,
  'info-400': $theme-info-400,
  'info-600': $theme-info-600,
  'info-800': $theme-info-800,

  'contrast-200': $theme-contrast-200,
  'contrast-400': $theme-contrast-400,
  'contrast-600': $theme-contrast-600,

  'black-25': $theme-black-25,
  'black-50': $theme-black-50,
  'black-100': $theme-black-100,
  'black-200': $theme-black-200,
  'black-300': $theme-black-300,
  'black-400': $theme-black-400,
  'black-500': $theme-black-500,
  'black-600': $theme-black-600,
  'black-700': $theme-black-700,
  'black-800': $theme-black-800,
  'black-900': $theme-black-900,

  'grey-50': $theme-grey-50,
  'grey-100': $theme-grey-100,
  'grey-200': $theme-grey-200,
  'grey-300': $theme-grey-300,
  'grey-400': $theme-grey-400,
  'grey-500': $theme-grey-500,
  'grey-600': $theme-grey-600,
  'grey-700': $theme-grey-700,
  'grey-800': $theme-grey-800,
  'grey-900': $theme-grey-900,

  'danger-100': $theme-danger-100,
  'danger-200': $theme-danger-200,
  'danger-400': $theme-danger-400,
  'danger-600': $theme-danger-600,
  'danger-700': $theme-danger-700,
  'danger-800': $theme-danger-800,
  'danger-900': $theme-danger-900,

  'warning-100': $theme-warning-100,
  'warning-200': $theme-warning-200,
  'warning-400': $theme-warning-400,
  'warning-600': $theme-warning-600,
  'warning-700': $theme-warning-700,
  'warning-800': $theme-warning-800,
  'warning-900': $theme-warning-900,
);

$cursor: (
  'pointer': 'pointer',
  'default': 'default',
  'not-allowed': 'not-allowed',
);
$z-index: (
  'null': '0',
  'xs': '1',
  'sm': '9',
  'md': '99',
  'lg': '999',
  'xl': '9999',
);
$display: (
  'flex': flex,
  'grid': grid,
  'block': 'block',
  'inline-flex': inline-flex,
  'inline-block': inline-block,
);

$align: (
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'stretch': stretch,
  'baseline': baseline,
);

$justify: (
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'around': space-around,
  'between': space-between,
  'evenly': space-evenly,
);

$gap: (
  '2xs': '.15',
  'xs': '.25',
  'sm': '.5',
  'md': '1',
  'lg': '1.5',
  'xl': '2',
  '2xl': '3',
  '3xl': '4',
);

$width-height: (
  'xs': '25',
  'sm': '50',
  'md': '75',
  'lg': '85',
  'full': '100',
);

$numeric-size: (
  '0': '0',
  '1': '1',
  '4': '4',
  '10': '10',
  '12': '12',
  '16': '16',
  '17': '17',
  '20': '20',
  '25': '25',
  '28': '28',
  '32': '32',
  '36': '36',
  '40': '40',
  '45': '45',
  '50': '50',
  '60': '60',
  '64': '64',
  '75': '75',
  '80': '80',
  '85': '85',
  '90': '90',
  '100': '100',
  '120': '120',
  '125': '125',
  '140': '140',
  '150': '150',
  '170': '170',
  '180': '180',
  '200': '200',
  '250': '250',
  '260': '260',
  '300': '300',
  '350': '350',
  '400': '400',
  '440': '440',
  '462': '462',
  '475': '475',
  '500': '500',
  '550': '550',
  '600': '600',
  '645': '645',
  '700': '700',
  '800': '800',
  '900': '900',
  '1000': '1000',
  '1150': '1150',
  '1200': '1200',
);
$margin-padding: (
  '0': '0',
  'xs': '0.25',
  'sm': '0.5',
  '2sm': '.8',
  'md': '1',
  'lg': '1.5',
  'xl': '2',
  '2xl': '2.5',
  '3xl': '3',
  '4xl': '3.4',
  '5xl': '4',
  '6xl': '6',
);

$font-size: (
  'xs': '10',
  '2sm': '12',
  // "sm": "13",
  'sm': '14',
  'md': '16',
  'lg': '18',
  'xl': '21',
  '2xl': '24',
  '3xl': '30',
  '4xl': '40',
  '5xl': '52',
  '8xl': '80',
);
$radius-size: (
  '0': '0',
  'xs': '3',
  '2xs': '4',
  'sm': '6',
  'md': '8',
  'lg': '12',
  'xl': '30',
  '2xl': '50',
  'full': '400',
);
$border-property: '1px solid';

$borders: (
  'top': $border-property,
  'left': $border-property,
  'right': $border-property,
  'bottom': $border-property,
  'full': $border-property,
);
$positions: (
  't': 'top',
  'l': 'left',
  'r': 'right',
  'b': 'bottom',
  'c': 'center',
);
$element-positions: (
  'relative': 'relative',
  'absolute': 'absolute',
  'fixed': 'fixed',
  'sticky': 'sticky',
);
$object-size: (
  'cover': 'cover',
  'contain': 'contain',
);
$opacity-size: (
  '0': '0',
  'xs': '.2',
  'sm': '.4',
  'md': '.6',
  'lg': '.8',
);
$overflow: (
  'auto': 'auto',
  'hidden': 'hidden',
);
